import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Combobox, Flex, Text } from "@mantine/core";
import Link from "next/link";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { getNameForHighlightColor } from "@/utils/ui";

export enum BadgeStyle {
  Active = "active",
  Inactive = "inactive",
  Mixed = "mixed",
}

export interface UniversalSearchResultProps {
  badgeContent: string;
  badgeStyle: BadgeStyle;
  breadcrumbs: string[];
  highlightColor?: string;
  href?: string;
  isLastOption: boolean;
  onClick: () => void;
  recordId: string;
  subTitle: string;
  title: string;
}

export const UniversalSearchResult = ({
  badgeStyle,
  badgeContent,
  breadcrumbs,
  highlightColor,
  href,
  isLastOption,
  onClick,
  recordId,
  subTitle,
  title,
}: UniversalSearchResultProps) => {
  const baseProps = useMemo(() => {
    if (href) {
      return {
        component: Link,
        href,
        onClick,
      } as const;
    }

    return {
      component: "button",
      onClick,
    } as const;
  }, [href, onClick]);

  const badgeProps = useMemo(() => {
    switch (badgeStyle) {
      case BadgeStyle.Active:
        return { c: "mint-green.10", color: "mint-green.2" };
      case BadgeStyle.Mixed:
        return { c: "#7CA6A0", color: "#E9F7F5" };
      case BadgeStyle.Inactive:
        return {
          c: "var(--mantine-color-gray-5)",
          color: "var(--mantine-color-gray-0)",
        };
    }
  }, [badgeStyle]);

  return (
    <Combobox.Option
      {...baseProps}
      className={twMerge([
        "data-[combobox-selected]:bg-[#f8f9fa]",
        "data-[combobox-selected]:text-inherit",
        "min-w-[340px]",
      ])}
      display="block"
      px={18}
      py={0}
      ta="start"
      value=""
      w="100%"
    >
      <Flex
        direction="column"
        className={isLastOption ? undefined : "border-b border-uw-gray-0"}
        py={15}
      >
        <Flex align="baseline" direction="row" justify="space-between" mb={9}>
          <ul className="flex list-none">
            {breadcrumbs.map((breadcrumb, index) => (
              <li
                className="flex items-center"
                key={`${recordId}_${breadcrumb}_${index}`}
              >
                {index !== 0 && (
                  <FontAwesomeIcon
                    className="text-xs mx-1.5"
                    icon={faArrowRight}
                  />
                )}
                <Text size="xs">{breadcrumb}</Text>
              </li>
            ))}
          </ul>
          <Badge classNames={{ label: "text-xs" }} ml={24} {...badgeProps}>
            {badgeContent}
          </Badge>
        </Flex>
        <Flex direction="column">
          <Text className="flex items-center" fw={600} size="sm">
            {title}
            {highlightColor && (
              <span
                aria-label={`Highlight Color: ${getNameForHighlightColor(highlightColor)}`}
                className="h-2 ml-2 rounded-full w-2"
                role="img"
                style={{ backgroundColor: highlightColor }}
              />
            )}
          </Text>
          <Text className="text-uw-gray-5" size="sm">
            {subTitle}
          </Text>
        </Flex>
      </Flex>
    </Combobox.Option>
  );
};
