import {
  CloseButton,
  Combobox,
  InputBase,
  type InputBaseProps,
  type PolymorphicComponentProps,
} from "@mantine/core";
import { forwardRef, type ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import type { Nullable } from "@/types/utils";

interface ComboboxTargetInputProps
  extends Partial<PolymorphicComponentProps<"input", InputBaseProps>> {
  leftSection?: Nullable<ReactNode>;
  onClear?: () => void;
  rightSection?: ReactNode;
  showClearButton?: boolean;
}

export const ComboboxTargetInput = forwardRef<
  HTMLInputElement,
  ComboboxTargetInputProps
>(
  (
    {
      leftSection = null,
      onClear,
      rightSection,
      showClearButton,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <Combobox.Target ref={ref}>
        <InputBase
          component="input"
          classNames={{
            input: twMerge([
              "border",
              "border-uw-gray-2",
              "rounded",
              "text-sm",
              "focus:border-uw-purple",
            ]),
          }}
          leftSection={leftSection}
          leftSectionPointerEvents="none"
          rightSection={
            showClearButton ? (
              <CloseButton
                aria-label="Clear value"
                c="purple.5"
                onClick={() => onClear?.()}
                onMouseDown={(event) => event.preventDefault()}
                size="sm"
              />
            ) : (
              rightSection
            )
          }
          rightSectionPointerEvents={showClearButton ? "all" : "none"}
          type="search"
          {...inputProps}
        />
      </Combobox.Target>
    );
  },
);
